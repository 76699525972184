import React, { useState } from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { postActionClick } from '@wix/bi-logger-groups/v2';
import { useSettings } from '@wix/tpa-settings/react';
import { useHref } from '@wix/tpa-router/react';
import type { RichContent } from '@wix/ricos';
import { EditorEventsProvider } from '@wix/ricos';

import {
  selectGroupPermissions,
  selectGroupSlugById,
  selectIsGroupSecret,
} from 'store/selectors';
import { useController } from 'common/context/controller';
import type { IFeedItem } from 'api/feed/types';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  More as MoreIcon,
  Notifications as NotificationsIcon,
  NotificationsFill as NotificationsFillIcon,
  Pin as PinIcon,
  PinFill as PinFillIcon,
  Share as ShareIcon,
} from '@wix/wix-ui-icons-common/on-stage';
import { Menu, MenuItem } from 'wui/Menu';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { ButtonGroup } from 'wui/ButtonGroup';
import { ShareDialog } from 'wui/ShareDialog';
import { IconButton } from 'wui/IconButton';

import * as feed from 'settings/feed';

import { FeedItemEditorDialog } from '../FeedItemEditorDialog';
import { DeleteFeedItemDialog } from './DeleteFeedItemDialog';

import classes from './FeedItem.scss';

interface IProps {
  item: IFeedItem;
}

interface IDialogsState {
  edit?: boolean;
  share?: boolean;
  delete?: boolean;
}

export function FeedItemMenu(props: IProps) {
  const { item } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { feed$ } = useController();
  const settings = useSettings();

  const { permissions } = item;
  const feedItemId = item.feedItemId as string;
  const groupId = item.applicationContext?.contextId as string;
  const isPinned = !!item.pin;
  const isFollowing = !!item.requesterContext?.subscribed;

  const groupPermissions = useSelector(selectGroupPermissions(groupId));
  const slug = useSelector(selectGroupSlugById(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));

  const canFollow =
    groupPermissions.feed?.canFollowPosts && item.permissions?.canViewFullPost;
  const canShare =
    !isGroupSecret &&
    Boolean(permissions?.canShare) &&
    settings.get(feed.settings.showShareButton);

  const showMenu =
    canFollow ||
    permissions?.canPin ||
    permissions?.canUpdate ||
    permissions?.canDelete ||
    (canShare && isMobile);

  const [dialogs, setDialogs] = useState<IDialogsState>({
    edit: false,
    share: false,
    delete: false,
  });

  const feedItemUrl = useHref(
    'group.discussion.post',
    { slug, feedItemId },
    { absolute: true },
  );

  return (
    <ButtonGroup>
      <Show if={canShare && !isMobile}>
        <Wire
          cssVarName="showShareButton--inline-block"
          legacyFallback={settings.get(feed.settings.showShareButton)}
        >
          <IconButton
            className={classes.iconButton}
            icon={<ShareIcon />}
            onClick={dialogOpenHandler('share')}
            aria-label={t('groups-web.discussion.feed.post-actions.share')}
          />
        </Wire>
      </Show>

      {showMenu && (
        <Menu
          element={
            <IconButton
              className={classes.iconButton}
              icon={<MoreIcon />}
              aria-label={t('groups-web.a11y.more-actions')}
            />
          }
        >
          {permissions?.canPin &&
            (isPinned ? (
              <MenuItem
                onClick={handleUnpin}
                prefixIcon={<PinFillIcon />}
                content={t(
                  'groups-web.discussion.feed.post-actions.unpin-post',
                )}
              />
            ) : (
              <MenuItem
                onClick={handlePin}
                prefixIcon={<PinIcon />}
                content={t('groups-web.discussion.feed.post-actions.pin-post')}
              />
            ))}

          {canFollow &&
            (isFollowing ? (
              <MenuItem
                onClick={handleUnfollow}
                prefixIcon={<NotificationsFillIcon />}
                content={t('groups-web.discussion.feed.post-actions.following')}
              />
            ) : (
              <MenuItem
                onClick={handleFollow}
                prefixIcon={<NotificationsIcon />}
                content={t('groups-web.discussion.feed.post-actions.follow')}
              />
            ))}

          {canShare && isMobile && (
            <MenuItem
              onClick={handleShare}
              prefixIcon={<ShareIcon />}
              content={t('groups-web.discussion.feed.post-actions.share')}
            />
          )}

          {permissions?.canUpdate && (
            <MenuItem
              onClick={handleEdit}
              prefixIcon={<EditIcon />}
              content={t('groups-web.discussion.feed.post-actions.edit')}
            />
          )}

          {permissions?.canDelete && (
            <MenuItem
              onClick={handleDelete}
              prefixIcon={<DeleteIcon />}
              content={t('groups-web.discussion.feed.post-actions.delete')}
            />
          )}
        </Menu>
      )}

      <DeleteFeedItemDialog
        item={item}
        isOpen={dialogs.delete}
        onClose={dialogCloseHandler('delete')}
      />
      <ShareDialog
        data={{ url: feedItemUrl }}
        isOpen={dialogs.share}
        canUseNative={isMobile}
        onClose={dialogCloseHandler('delete')}
        title={t('groups-web.discussion.feed.post-actions.share.dialog')}
      />
      <EditorEventsProvider>
        <FeedItemEditorDialog
          item={item}
          groupId={groupId}
          isOpen={dialogs.edit}
          onClose={dialogCloseHandler('edit')}
        />
      </EditorEventsProvider>
    </ButtonGroup>
  );

  function dialogOpenHandler(dialog: keyof typeof dialogs) {
    return () => openDialog(dialog);
  }

  function dialogCloseHandler(dialog: keyof typeof dialogs) {
    return () => closeDialog(dialog);
  }

  function openDialog(dialog: keyof typeof dialogs) {
    setDialogs({ [dialog]: true });
  }

  function closeDialog(dialog: keyof typeof dialogs) {
    setDialogs({ [dialog]: false });
  }

  function handlePin() {
    reportAction('pin');

    feed$.pin(groupId, item.feedItemId as string);
  }

  function handleUnpin() {
    reportAction('unpin');

    feed$.unpin(groupId, item.feedItemId as string);
  }

  function handleFollow() {
    reportAction('follow');

    feed$.subscribe(groupId, item.feedItemId as string);
  }

  function handleUnfollow() {
    reportAction('unfollow');

    feed$.unsubscribe(groupId, item.feedItemId as string);
  }

  function handleDelete() {
    openDialog('delete');
    reportAction('delete');
  }

  function handleEdit() {
    openDialog('edit');
    reportAction('edit');
  }

  function handleShare() {
    openDialog('share');
    reportAction('share');
  }

  function update(content: RichContent, topicIds: string[]) {
    feed$.update(groupId, item.feedItemId as string, content, topicIds);
  }

  function reportAction(action: string) {
    bi.report(
      postActionClick({
        action,
        groupId,
        postId: feedItemId,
      }),
    );
  }
}

FeedItemMenu.displayName = 'FeedItemMenu';
